.fa-github {
  color: var(--text-primary);
}

.nav-menu {
  min-height: 100vh;
  top: 0;
  width: auto;
  /* mobile first */
  max-width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  overflow-x: hidden;
  color: var(--text-primary);
  backdrop-filter: blur(8px);
  position: fixed;
  left: -50rem;
  z-index: 2000;
  border-right: 1px solid var(--border_color);
  padding-right: 2rem;
  animation: close-navmenu 0.75s;
  display: flex;
  flex-direction: column;
}

/* for tablets */
@media only screen and (min-width: 600px) {
  .nav-menu {
    max-width: 35%;
  }
}

/* for laptops */
@media only screen and (min-width: 1024px) {
  .nav-menu {
    max-width: 20%;
  }
}

/* just hiding the inner scrollbar in the navbar */
.nav-menu::-webkit-scrollbar {
  display: none;
}

.nav-menu.active {
  animation: open-navmenu 0.75s;
  transform: translateX(50rem);
}

.nav-menu-logo {
  padding-left: 0.5rem;
}

.nav-list, .nav-item {
  text-align: left;
  width: calc(100% + 2rem);
  left: -20px;
  margin: 0px;
  animation: ease-in-out 3s normal infinite;  
}

.nav-item, .nav-links {
    width: 100%;
}

/* makes the whole width clickable */
.nav-links { 
    display: block;
    padding-left:30px;
}

.nav-item:hover {
  left: 0%;
  background-color: var(--secondary-background-color);
}

.nav-menu-toggle {
  position: fixed;
  left: 0%;
  top: 0%;
  z-index: 9999;
  padding-left:0.5rem;
}


@keyframes open-navmenu {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(50rem);
    timing-function: ease-in;
  }
}

@keyframes close-navmenu {
  0% {
    transform: translateX(50rem);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}


.nav-menu-permission-badge {
    position: absolute;
    bottom: 30%;
    left: 20%;
    display: inline-flex;
    align-items: flex-end;
}