:root {
  --background-color: white;
  --secondary-background-color: rgb(200, 200, 200);
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;
  --link-color: darkblue;
  --border-color: darkgrey;
  --modal-background: lightgrey;
  overflow-x: hidden;
  overflow-y: auto;
  --nextui-colors-accents0: lightgrey;
  --ck-color-base-background: lightgrey;
  --ck-color-base-background: lightgrey;
}

[data-theme='dark'] {
  --background-color: black;
  --secondary-background-color: rgb(64, 45, 45);
  --ck-color-base-background: #0d0d0d;
  --modal-background: #0d0d0d;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
  --link-color: lightblue;
  --border-color: lightgrey;
  --nextui-colors-accents0: #16181A;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: var(--modal-background) !important;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color);
}

ul li {
  display: list-item;
}

.App {
  color: var(--text-primary);
  background-color: var(--background-color);
  font-size: large;
  font-weight: bold;
  padding: 0;
  margin: 0;
  z-index: 5;
  /* this way stuff can be hidden behind it*/
  transition: all .5s;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.AnimatedButtonBox {
  border-color: var(--border-color);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-style: solid;
  min-width: 55%;
  margin: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 4px;
  animation: ease-in-out 3s normal infinite;
}

.AnimatedButtonBox:hover {
  animation-name: gentleBounce;
  animation-timing-function: ease;
}

@keyframes gentleBounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}

.errorBounce {
  outline: 0;
  border-color: red;
  animation-name: gentleBounce;
  animation-duration: .5s;
  animation-delay: 0.25s;
}

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes errorBounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }

  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }

  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }

  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }

  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }

  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }

  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }

  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}



/* date picker, so that it shows up on top */
._3curk {
  position: fixed;
  z-index: 100000;
  color: Black;
}

.fa-trash-can,
.fa-heart {
  color: red;
}




/* not used currently */
@keyframes beat {
  to {
    transform: scale(7.4);
  }
}



@keyframes strike {
  0% {
    width: 0;
  }

  100% {
    width: 101%;
  }
}

.strike {
  position: relative;
}

.strike::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: -2px;
  width: 100%;
  height: 2.5px;
  background: black;
  animation-name: strike;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.striked {
  position: relative;
}

.striked::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: -2px;
  width: 100%;
  height: 2.5px;
  background: black;
}

.line-primary {
  color: white;
  width: 100vw;
  height: 5px;
}

/* its a weird seperator in the Card components */
.nextui-c-bZLhCa-iiMwZzC-css:not(.intentional-divider) {
  height: 0;
}

.nextui-navbar-container {
  min-width: 100%;
}



.gradient-anim {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.background-video-1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  animation: ease-out 0.75s forwards;
  animation-name: slowColorReversion;
}

.background-video-1.invert {
  animation-name: slowColorInversion;
  animation-duration: .75s;
  animation-delay: 0.25s;
}

@keyframes slowColorInversion {
  0% {
    filter: invert(0%);
  }

  100% {
    filter: invert(100%) hue-rotate(180deg);
  }
}

@keyframes slowColorReversion {
  0% {
    filter: invert(100%) hue-rotate(180deg);
  }

  100% {
    filter: invert(0%);
  }
}


.nextui-tooltip-wrapper-enter-active[data-state='open'] {
  z-index: 9999;
}