/* declares variables */
:root { 
  --primary: #fff;
  --secondary: #242424;
  --shadow: orange;
}
/* dark theme variable overwrite */
[data-theme='dark'] {
  --primary: black;
  --secondary: #fff;
  --shadow: blue;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  min-width: 60px;
  outline: none;
  border: none;
  cursor: pointer;
}

.disabled {
  color: grey;
  cursor: not-allowed;
  pointer-events: none;
}

.rounded {
  border-radius: 35%
}

.shadow:hover {
  filter: 
    drop-shadow(0px 0px 1rem var(--shadow))
    drop-shadow(0px 0px 2rem var(--shadow));
}

.disabled.btn--transparent {
  background-color: transparent;
}

.btn--primary:not(.disabled) {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.btn--secondary:not(.disabled) {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--secondary);
}

.btn--outline:not(.disabled) {
  background-color: transparent;
  color: var(--secondary);
  padding: 8px 20px;
  border: 1px solid var(--secondary);
  transition: all 0.3s ease-out;
}

.btn--transparent:not(.disabled) {
  background-color: transparent;
  color: var(--secondary);
  padding: 8px 20px;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--small {
  /* padding: 4px 10px; */
  font-size: 15px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: var(--secondary);
  color: var(--primary);
  transition: 250ms;
}